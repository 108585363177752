<template>
  <div>
    404 Error
  </div>
</template>

<script>
export default {
  created: function(){
    let self = this
    setTimeout(function(){
      self.$router.push({name:'Home'})
    },2000)
  }
}
</script>

<style>

</style>